<template>
	<div>
		<Navbar />
		<Header title="Campaigns" excerpt="See your past message history and check" />

		<!-- CONTENT -->
		<b-container>
			<b-card no-body style="border-color: #e1e6ee; border-width: 2px;">
				<b-card-body class="p-0">
					<b-table hover :items="messages" :fields="fields">
						<template #cell(time)="data">{{ new Date(data.value.created * 1000).toLocaleString("en-US", { timeZone: "Asia/Colombo" }) }}</template>
					</b-table>
				</b-card-body>
			</b-card>
			<b-pagination
				class="float-right mt-2"
				:total-rows="this.$store.state.history.pages.total * this.$store.state.history.pages.limit"
				:per-page="this.$store.state.history.pages.limit"
				:value="this.$store.state.history.pages.current"
				:hide-goto-end-buttons=true
				v-on:change="On_Paginate">
			</b-pagination>
		</b-container>

	</div>
</template>

<script>
import Header from "@/components/Header";
import Navbar from '@/components/Navbar';

export default {
	name: "History",
	components: { Header, Navbar },

	data: () => ({
		fields: [
			{
				key: "id",
				label: "#"
			},
			{
				key: "recipient",
				label: "Recipient"
			},
			{
				key: "text",
				label: "Message"
			},
			{
				key: "cost",
				label: "Cost ( Rs )"
			},
			{
				key: "time",
				label: "Time"
			}
		]
	}),

	computed: {
		messages() {
			return this.$store.state.history.messages
		}
	},

	mounted() {
		this.$store.dispatch("history/initialize")
	},

	methods: {

		On_Paginate: function(page) { this.$store.dispatch("history/fetch", { page: page }) }

	}
}
</script>
